import React, { useCallback } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
import { defineMessages, useIntl } from 'react-intl-next';

import {
	Card as SmartCard,
	MediaPlacement,
	PreviewBlock,
	SnippetBlock,
	SmartLinkSize,
	SmartLinkTheme,
	TitleBlock,
	CustomBlock,
} from '@atlaskit/smart-card';

import { markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { CardSizes } from '../../linkCardsTypes';
import type { MediaConfigType } from '../../linkCardsTypes';
import type { OnCardFailed, OnCardSucceeded } from '../useCardsCombinedExperienceState';

import {
	EmptyAndSmallCardWrapper as EmptyCardWrapper,
	getTitleBlockStyles,
	CommonCardWrapperStyles,
	emptyDescriptionBlockStyles,
	getCustomImageUploadStyles,
} from './cardComponentsStyles';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import { useSmartCardFallbackUrl } from './useSmartCardFallbackUrl';

type LinkCardsContentProps = {
	cardId: string;
	title?: string;
	imageSrc?: string;
	description?: string;
	size: CardSizes;
	cardHeight: number;
	isInViewMode: boolean;
	onCardSucceeded: OnCardSucceeded;
	onCardFailed: OnCardFailed;
	mediaConfig?: MediaConfigType;
	imageAltText?: string;
};

const i18n = defineMessages({
	emptyStatePlaceholderTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.empty-state.placeholder-title',
		defaultMessage: 'Add a title',
		description: 'Placeholder title that is displayed in Smart Card when no title is provided',
	},
});

const previewStyles = css({
	flexGrow: 1,
});

export const EmptyCard = ({
	cardId,
	size,
	title,
	description,
	imageSrc,
	cardHeight,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	mediaConfig,
	imageAltText,
}: LinkCardsContentProps) => {
	const intl = useIntl();

	const handleResolve = useCallback(() => {
		onCardSucceeded(cardId);
	}, [onCardSucceeded, cardId]);

	const handleError = useCallback(
		(error: any) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.COMPANY_HUB,
			});
			markErrorAsHandled(error);
			onCardFailed(error);
		},
		[onCardFailed],
	);

	const url = useSmartCardFallbackUrl();

	const { showUnsplashImage, showCustomUploadedImage, hasNoImage } = getImageTypeToShow({
		imageSrc,
		mediaConfig,
	});

	return (
		<CommonCardWrapperStyles isInViewMode={isInViewMode} isEmptyCard>
			<EmptyCardWrapper
				size={size}
				data-testid="empty-state-visual-smart-card"
				cardHeight={cardHeight}
				isEmptyCard
				hasNoImage={hasNoImage}
			>
				<SmartCard
					id={cardId}
					onError={handleError}
					onResolve={handleResolve}
					appearance="inline"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: size === CardSizes.LARGE ? SmartLinkSize.XLarge : SmartLinkSize.Large,
					}}
					url={url}
				>
					{showUnsplashImage && (
						<PreviewBlock
							ignoreContainerPadding
							placement={size === CardSizes.SMALL ? MediaPlacement.Left : undefined}
							overrideCss={previewStyles}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock
							overrideCss={getCustomImageUploadStyles(
								size === CardSizes.SMALL ? MediaPlacement.Left : undefined,
							)}
						>
							<CustomImageUpload
								imageSrc={imageSrc}
								mediaConfig={mediaConfig}
								imageAltText={imageAltText}
								shouldShowErrorMessage={!(size === CardSizes.SMALL)}
							/>
						</CustomBlock>
					)}
					<TitleBlock
						text={title || intl.formatMessage(i18n.emptyStatePlaceholderTitle)}
						overrideCss={getTitleBlockStyles({
							isEmptyCard: true,
							isSmallCard: size === CardSizes.SMALL,
							isDisabledTextColor: !title,
						})}
						hideIcon
						hideTitleTooltip
						maxLines={1}
						anchorTarget="_self"
					/>
					{size !== CardSizes.SMALL && (
						<SnippetBlock
							text={description}
							overrideCss={emptyDescriptionBlockStyles({
								isDisabledTextColor: !description,
							})}
						/>
					)}
				</SmartCard>
			</EmptyCardWrapper>
		</CommonCardWrapperStyles>
	);
};
