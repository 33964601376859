import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type { CardSizes } from '../linkCardsTypes';

import { cardsDimensions } from './GridCardWrapper';
import { MIN_GAP_BETWEEN_CARDS, MAX_GAP_BETWEEN_CARDS } from './CardsGrid';

export type useCardWidthAndSpacingCalculationsProps = {
	numberOfCards: number;
	cardsSize: CardSizes;
};

export type GridGaps = {
	spaceBetweenCards: number;
	paddingRightGapOffset: number;
};

type Calculations = {
	cardWidthOnFullRow: number;
	isFirstRowFull: boolean;
	isGridSmallerOrEqualWidthToCards: boolean;
	gaps: GridGaps;
};

type useCardWidthAndSpacingCalculationsResults = {
	calculations: Calculations;
	setCurrentCardsGridWidth: Dispatch<SetStateAction<number>>;
	setCurrentCardWidth: Dispatch<SetStateAction<number>>;
};

export const useCardWidthAndSpacingCalculations = ({
	numberOfCards,
	cardsSize,
}: useCardWidthAndSpacingCalculationsProps): useCardWidthAndSpacingCalculationsResults => {
	const [currentCardsGridWidth, setCurrentCardsGridWidth] = useState<number>(0);

	const [currentCardWidth, setCurrentCardWidth] = useState<number>(0);

	/*
	 * Width observer rounds the width of the container (currentCardsGridWidth) returning a whole number.
	 * This means in situations that the width is rounded up, we have an edge case where we are
	 * making calculations with a grid width larger than what we actually have leading to layout issues.
	 *
	 * It is better to adjust the width we get from the observer down (-.5px) and
	 * have some cases where we have extra space (<=.5px) than the latter problem that causes layout issues.
	 */
	const currentCardsGridWidthAdjustedForRounding = currentCardsGridWidth - 0.5;

	if (currentCardsGridWidthAdjustedForRounding <= cardsDimensions[cardsSize].minWidth)
		return {
			calculations: {
				cardWidthOnFullRow: cardsDimensions[cardsSize].minWidth,
				isFirstRowFull: true,
				isGridSmallerOrEqualWidthToCards: true,
				gaps: {
					spaceBetweenCards: MIN_GAP_BETWEEN_CARDS,
					paddingRightGapOffset: 0,
				},
			},
			setCurrentCardsGridWidth,
			setCurrentCardWidth,
		};

	const maxNumberOfCardsPerRow = Math.floor(
		(currentCardsGridWidthAdjustedForRounding + MIN_GAP_BETWEEN_CARDS) /
			(cardsDimensions[cardsSize].minWidth + MIN_GAP_BETWEEN_CARDS),
	);

	const maxCardSizeOnFullRow = Math.floor(
		(currentCardsGridWidthAdjustedForRounding -
			MIN_GAP_BETWEEN_CARDS * (maxNumberOfCardsPerRow - 1)) /
			maxNumberOfCardsPerRow,
	);

	const isFirstRowFull = numberOfCards >= maxNumberOfCardsPerRow;

	const cardWidthOnFullRow = Math.min(maxCardSizeOnFullRow, cardsDimensions[cardsSize].maxWidth);

	const cardsOnFirstRow = Math.min(numberOfCards, maxNumberOfCardsPerRow);

	const numberOfRowGaps = Math.max(0, cardsOnFirstRow - 1);

	const extraSpaceInGrid =
		currentCardsGridWidthAdjustedForRounding -
		cardsOnFirstRow * currentCardWidth -
		numberOfRowGaps * MIN_GAP_BETWEEN_CARDS;

	const maxAdditionalSpacePerGapBetweenCards =
		numberOfRowGaps === 0
			? 0
			: Math.min(extraSpaceInGrid / numberOfRowGaps, MAX_GAP_BETWEEN_CARDS - MIN_GAP_BETWEEN_CARDS);

	const spaceBetweenCards = MIN_GAP_BETWEEN_CARDS + maxAdditionalSpacePerGapBetweenCards;

	const paddingRightGapOffset = !isFirstRowFull
		? 0
		: (extraSpaceInGrid - numberOfRowGaps * maxAdditionalSpacePerGapBetweenCards) / 2;

	const calculations: Calculations = {
		cardWidthOnFullRow,
		isFirstRowFull,
		isGridSmallerOrEqualWidthToCards: false,
		gaps: {
			spaceBetweenCards,
			paddingRightGapOffset,
		},
	};

	return { calculations, setCurrentCardsGridWidth, setCurrentCardWidth };
};
