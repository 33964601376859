import React, { useRef, useEffect, useCallback } from 'react';
import type { FC } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import {
	Card as SmartCard,
	CustomBlock,
	ElementName,
	MetadataBlock,
	PreviewBlock,
	SmartLinkSize,
	SmartLinkTheme,
	SnippetBlock,
	TitleBlock,
} from '@atlaskit/smart-card';
import { token } from '@atlaskit/tokens';

import { CardSizes } from '../../linkCardsTypes';
import { EmojiRenderer } from '../EmojiRenderer';
import { useSmartCardEmoji } from '../useSmartCardEmoji';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';

import {
	CommonCardWrapperStyles,
	floatingEmojiStyles,
	getTitleBlockStyles,
	getCustomImageUploadStyles,
	MediumLargeCardWrapper as CardWrapper,
} from './cardComponentsStyles';
import { CardErrorState } from './ErrorStates';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import type { CommonCardProps } from './commonCardProps';

const mediumCardTitleBlockCss = (isUnauthorized) =>
	css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		...getTitleBlockStyles({ isUnauthorized }),
		paddingTop: token('space.250', '20px'),
	});

export const MediumCard: FC<CommonCardProps> = ({
	cardId,
	link,
	title,
	imageSrc,
	cardHeight,
	isAvatarShown,
	isPublishDateShown,
	description,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	mediaConfig,
	imageAltText,
	createAnalyticsEvent,
	...analyticsPassThroughProps
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);

	const onResolve = useCallback(() => {
		trackSuccess();

		if (
			titleBlockRef.current &&
			isAvatarShown &&
			titleBlockRef.current.offsetHeight >= titleBlockRef.current.scrollHeight
		) {
			titleHeight.current = titleBlockRef.current.getBoundingClientRect().height;
		}
	}, [trackSuccess, isAvatarShown]);

	const { emojiId } = useSmartCardEmoji(link);
	const titleBlockRef = useRef<HTMLDivElement>(null);
	const titleHeight = useRef<number>(0);
	const { showUnsplashImage, showCustomUploadedImage, hasNoImage } = getImageTypeToShow({
		imageSrc,
		mediaConfig,
	});

	useEffect(() => {
		onResolve();
	}, [onResolve]);

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.MEDIUM}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				{...analyticsPassThroughProps}
			/>
		);

	return (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<CardWrapper
				cardHeight={cardHeight}
				titleHeight={titleHeight.current}
				hasNoImage={hasNoImage}
			>
				<SmartCard
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isInViewMode,
						size: SmartLinkSize.Medium,
					}}
					url={link}
					onResolve={onResolve}
					onError={trackAndCategorizeError}
				>
					{showUnsplashImage && <PreviewBlock ignoreContainerPadding overrideUrl={imageSrc} />}
					{showCustomUploadedImage && (
						<CustomBlock overrideCss={getCustomImageUploadStyles()}>
							<CustomImageUpload
								imageSrc={imageSrc}
								mediaConfig={mediaConfig}
								imageAltText={imageAltText}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock
						overrideCss={floatingEmojiStyles}
						testId="smart-block-link-card-emoji-container"
					>
						<EmojiRenderer emojiId={emojiId} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.AuthorGroup }] : []}
						text={title}
						anchorTarget="_self"
						overrideCss={mediumCardTitleBlockCss(isUnauthorized)}
						blockRef={titleBlockRef}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{!isUnauthorized && <SnippetBlock text={description} />}
				</SmartCard>
			</CardWrapper>
		</CommonCardWrapperStyles>
	);
};
