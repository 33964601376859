import React from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';

import { WidthObserver } from '@atlaskit/width-detector';

import type { CardSizes } from '../linkCardsTypes';
import { CardAlignment } from '../linkCardsTypes';

import { GridCardWrapper } from './GridCardWrapper';
import { useCardWidthAndSpacingCalculations } from './useCardWidthAndSpacingCalculations';
import type { GridGaps } from './useCardWidthAndSpacingCalculations';

export const MIN_GAP_BETWEEN_CARDS = 16;
export const MAX_GAP_BETWEEN_CARDS = 24;

type CardsGridProps = React.PropsWithChildren<{
	numberOfCards: number;
	cardsSize: CardSizes;
	cardsAlignment: CardAlignment;
}>;

type FlexBoxProps = {
	alignment: CardAlignment;
	gaps: GridGaps;
	isGridSmallerOrEqualWidthToCards: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexBox = styled.div<FlexBoxProps>({
	display: 'flex',
	flexWrap: 'wrap',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ alignment, gaps, isGridSmallerOrEqualWidthToCards }) => ({
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
		gap: `${gaps.spaceBetweenCards}px`,
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview
		paddingLeft:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			alignment === CardAlignment.CENTER ? '0px' : `${gaps.paddingRightGapOffset}px`,
		justifyContent:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			alignment === CardAlignment.CENTER && !isGridSmallerOrEqualWidthToCards
				? 'center'
				: 'flex-start',
	}),
});

export const CardsGrid = ({
	numberOfCards,
	cardsSize,
	cardsAlignment,
	children,
}: CardsGridProps) => {
	const { calculations, setCurrentCardsGridWidth, setCurrentCardWidth } =
		useCardWidthAndSpacingCalculations({
			numberOfCards,
			cardsSize,
		});

	const { cardWidthOnFullRow, isFirstRowFull, isGridSmallerOrEqualWidthToCards, gaps } =
		calculations;

	const throttledSetWidth = throttle(setCurrentCardsGridWidth, 200);

	return (
		<FlexBox
			alignment={cardsAlignment}
			gaps={gaps}
			isGridSmallerOrEqualWidthToCards={isGridSmallerOrEqualWidthToCards}
			data-testid="link-cards-content-cards-grid"
		>
			{!!children &&
				React.Children.map(children, (child, index) => {
					if (!React.isValidElement(child)) return;

					return (
						<GridCardWrapper
							key={index}
							isFirstRowFull={isFirstRowFull}
							cardWidthOnFullRow={cardWidthOnFullRow}
							cardSize={cardsSize}
							watchWidth={index === 0 ? setCurrentCardWidth : undefined}
							testId="link-cards-content-cards-grid-card"
						>
							{child}
						</GridCardWrapper>
					);
				})}
			<WidthObserver setWidth={throttledSetWidth} offscreen />
		</FlexBox>
	);
};
